function condenseNavbar() {

	condensedNavs = document.getElementsByClassName("condensed-nav");

	[].forEach.call(condensedNavs, function (dropdown) {

		var dropdownMenu = dropdown.querySelector(".dropdown-menu");
		var links = document.querySelectorAll('[data-parent="#' + dropdown.id + '"]');

		dropdownMenu.innerHTML = "";

		[].forEach.call(links, function (link) {

			if (window.getComputedStyle(link, null).display == "none") {

				newElement = document.createElement("a");
				newElement.classList.add("dropdown-item");
				newElement.href = link.href;
				newElement.title = link.title;
				newElement.innerHTML = link.innerHTML;

				dropdownMenu.appendChild(newElement);
			}
		});
	});
}

window.addEventListener("resize", condenseNavbar);

condenseNavbar();
