firebase.initializeApp(firebaseConfig);
firebase.analytics();

if (firebase.messaging.isSupported())
{
	messaging = firebase.messaging();

	Notification.requestPermission().then(function (permission) {
		if (permission == "granted")
			return messaging.getToken();
	}).then(function (token) {
		if (typeof token == 'undefined')
			document.cookie = "firebase_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
		else
			document.cookie = "firebase_token=" + token;
	});

	messaging.onMessage(function (payload) {
		var notification = new Notification(payload.notification.title, {body: payload.notification.body});
	});
}
